<template>
  <v-app>
    <v-app-bar
      app
      color="blue-grey-darken-2"
      dense
      dark
      v-show="isAppBarVisible()"
      clipped-left
      >
      <v-app-bar-nav-icon
          v-on:click="toggleNavBarVisibility()">
      </v-app-bar-nav-icon>
      <v-toolbar-title>jtWMS</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
          left
          bottom
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>{{ getBellBadge() }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>No new notifications</v-list-item-title>
          </v-list-item>
          <v-list-item
              @click="viewNotifications"
          >
            <v-list-item-title>View all notifications...</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu
        left
        bottom
        offset-y
      >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list
          dense
      >
        <v-list-item
            @click="displayDocumentation"
        >
          <v-list-item-title>Documentation...</v-list-item-title>
        </v-list-item>
        <v-list-item
            @click="toggleDarkLightMode"
        >
          <v-list-item-title id="toggleDarkLightModeItemText">{{ toggleDarkModeText }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="logout"
        >
          <v-list-item-title >Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
  <v-navigation-drawer
      app
      :permanent="isDrawerVisible"
      :width="calculateNavBarHeight()"
      clipped
      flat
      v-show="isDrawerVisible"
  >
    <Nav />
  </v-navigation-drawer>
  <v-main
  >
    <v-container
      fluid
    >
      <router-view></router-view>
    </v-container>
  </v-main>
  <v-footer app dense color="#EEEEEE">
    <v-spacer></v-spacer>
    jtWMS - 2024
    <v-spacer></v-spacer>
    Status: OK
  </v-footer>
</v-app>
</template>

<script setup>

</script>

<script>
import Nav from './components/Nav.vue';
import * as users from './js/users';
import * as configs from './js/configurations';

export default {
  name: 'App',
  components: {
    Nav
  },
  data: () => ({
    isDrawerVisible: false,
    isNavBarVisible: false,
    group: null,
    toggleDarkModeText: 'Turn on dark mode',
    notificationsExist: true,
  }),
  methods: {
    isAppBarVisible() {
      if (users.isUserLoggedIn()) {
        return true;
      } else {
        return false;
      }
    },
    calculateNavBarHeight() {
      if (!users.isUserLoggedIn() || !this.isDrawerVisible) {
        return 0;
      }
    },
    async logout() {
      localStorage.removeItem("user");
      this.isDrawerVisible = false;
      this.isNavBarVisible = false;
      await this.$router.push('/login');
    },
    async toggleDarkLightMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (this.$vuetify.theme.dark) {
        this.toggleDarkModeText = "Turn on light mode";
      } else {
        this.toggleDarkModeText = "Turn on dark mode";
      }
    },
    toggleNavBarVisibility: function() {
      const navPreference = users.getUserNavPreference();
      console.log("Nav preference before: ", navPreference);
      console.log("Nav preference negated: ", !navPreference);
      users.setUserNavPreference(!navPreference);
      this.isDrawerVisible = !navPreference;
    },
    async displayDocumentation() {
      let a= document.createElement("a");
      a.target= "_blank";
      a.href= "http://" + configs.getHostUrl() + ":" + configs.getPort() + "/api/docs/jasons-wms-home/";
      a.click();
    },
    viewNotifications: function() {

    },
    getBellBadge() {
      if (this.notificationsExist) {
        return 'mdi-bell-badge';
      }

      return 'mdi-bell';
    }
  },
  mounted: function() {
    this.isDrawerVisible = users.getUserNavPreference();

    const hasDarkPreference = window.matchMedia("(prefers-color-scheme: dark)").matches;
    this.$vuetify.theme.dark = users.getUserDarkModePreference();
  }
};
</script>
