import Vue from 'vue';
import App from './App.vue';
import Orders from './components/Orders.vue';
import OrderDetails from './components/OrderDetails.vue';
import Demo01 from './components/Demo/Demo01.vue';
import InventoryLookup from './components/inventory/InventoryLookup.vue';
import WarehouseOverview from './components/Dashboard/WarehouseOverview/WarehouseOverview.vue';
import DeliveryOverview from './components/inventory/DeliveryOverview.vue';
import DeliveryDetails from './components/inventory/DeliveryDetails.vue';
import Login from './components/Login.vue';
import Configurations from './components/WarehouseConfiguration/Configurations.vue';
import InductDelivery from './components/inventory/ReceivingDialog/InductDeliveryContainer.vue';
import ConfirmDeliveryContainer from './components/inventory/ReceivingDialog/ConfirmDeliveryContainer.vue';
import DropOffDeliveryContainer from './components/inventory/ReceivingDialog/DropOffDeliveryContainer.vue';
import Putaways from './components/inventory/Putaways.vue';
import BuildPutawayCart from './components/rf/Putaway/BuildPutawayCart.vue';
import AddPutawayToCart from './components/rf/Putaway/AddPutawayToCart.vue';
import InductPutawayCart from './components/rf/Putaway/InductPutawayCart.vue';
import LoginZone from './components/rf/Picking/LoginZone.vue';
import BuildCluster from './components/rf/Picking/BuildCluster.vue';
import Scan from './components/rf/Picking/Scan.vue';
import Pick from './components/rf/Picking/Pick.vue';
import AddPickContainerToCart from './components/rf/Picking/AddPickContainerToCart.vue';
import Users from './components/WarehouseConfiguration/Users';

import axios from 'axios';
import VueRouter from 'vue-router';
import { setConfigurations } from './js/configurations.js';
import vuetify from './plugins/vuetify';
import jwtDecode from "jwt-decode";
import jwt_decode from "jwt-decode";
import WarehouseLocations from "@/components/WarehouseConfiguration/WarehouseLocations";
import ViewQcTasks from "@/components/QualityControl/ViewQcTasks";
import UsersRolesOverview from "@/components/WarehouseConfiguration/UsersRolesOverview";
import Products from "@/components/inventory/Products";
import ReportFeedback from "@/components/ReportFeedback.vue";
import CreateAccount from "@/components/Accounts/CreateAccountWizard.vue";
import PurchaseOrder from "@/components/inventory/PurchaseOrders/PurchaseOrder.vue";
import CreatePurchaseOrder from "@/components/inventory/PurchaseOrders/CreatePurchaseOrder.vue";
import CreateEditDelivery from "@/components/inventory/CreateEditDelivery.vue";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.use(VueRouter);
Vue.prototype.$jwt = jwtDecode;

const routes = [
  { path: '', redirect:'/warehouseOverview', component: WarehouseOverview },
  { path: '/', redirect: '' },
  { path: '*', redirect: '' },
  { path: '/warehouseOverview', name: 'view_warehouseOverview', component: WarehouseOverview},
  { path: '/login', name: 'login', component: Login },
  { path: '/orders/:orderId', name: 'view_orderDetails', component: OrderDetails },
  { path: '/orders', name: 'view_orders', component: Orders },
  { path: '/delivery/inductDeliveryContainer', name: 'workflow_inductDelivery', component: InductDelivery },
  { path: '/delivery/confirmDeliveryContainer/:deliveryContainerId', name: 'workflow_confirmDeliveryContainer', component: ConfirmDeliveryContainer },
  { path: '/delivery/dropOffDeliveryContainer/:deliveryContainerId', name: 'workflow_dropOffDeliveryContainer', component: DropOffDeliveryContainer },
  { path: '/delivery/createEditDelivery', name: 'view_createDelivery', component: CreateEditDelivery },
  { path: '/delivery/createEditDelivery/:deliveryId', name: "view_createDelivery", component: CreateEditDelivery },
  { path: '/putaways', name: 'view_putaways', component: Putaways},
  { path: '/rf/picking/scan', name: 'workflow_pickScan', component: Scan },
  { path: '/rf/picking/pick', name: 'workflow_pick', component: Pick },
  { path: '/demo', name: 'view_demo', component: Demo01 },
  { path: '/inventoryLookup/:skuId', name: 'view_inventoryLookup', component: InventoryLookup },
  { path: '/inventoryLookup/', name: 'view_inventory', component: InventoryLookup },
  { path: '/delivery', name: 'view_deliveries', component: DeliveryOverview },
  { path: '/delivery/:deliveryId', name: 'view_deliveryDetails', component: DeliveryDetails },
  { path: '/putaway/cart/buildPutawayCart', name: 'workflow_buildPutawayCart', component: BuildPutawayCart },
  { path: '/putaway/cart/:cartId/addPutawayToCart', name: 'workflow_addPutawayToCart', component: AddPutawayToCart },
  { path: '/putaway/cart/:cartId/induct', name: 'workflow_InductPutawayCart', component: InductPutawayCart },
  { path: '/picking/loginZone', name: 'workflow_loginZone', component: LoginZone },
  { path: '/picking/cart/build', name: 'workflow_buildPickingCart', component: BuildCluster },
  { path: '/picking/cart/:cartId/addContainer', name: 'workflow_addPickContainerToCart', component: AddPickContainerToCart },
  { path: '/administration/Configurations', name: 'view_Configurations', component: Configurations },
  { path: '/administration/warehouseLocations', name: 'view_WarehouseLocations', component: WarehouseLocations },
  { path: '/administration/Users', name: 'view_users', component: Users},
  { path: '/administration/UsersRoles', name: 'view_users_roles', component: UsersRolesOverview },
  { path: '/qc/QcTasks', name: 'view_qcTasks', component: ViewQcTasks },
  { path: '/inventory/products', name: 'view_products', component: Products },
  { path: '/feedback/report', name: 'view_reportFeedback', component: ReportFeedback },
  { path: '/accounts/createAccount', name: 'view_createAccount', component: CreateAccount },
  { path: '/inventory/purchaseOrders', name: 'view_purchaseOrders', component: PurchaseOrder },
  { path: '/inventory/createPurchaseOrder', name: 'view_createPurchaseOrder', component: CreatePurchaseOrder },
  { path: '/administration/simulation', name: 'view_simulation', component: Demo01 }
]

const router = new VueRouter({
  routes
})

setConfigurations();
router.beforeEach(async (to, from, next) => {
  let userToken = localStorage.getItem("user");
  if (to.name === 'view_createAccount') {
    next();
    return;
  }

  if (to.name !== 'login' && (!userToken)) {
    next({ name: 'login' });
  } else if (to.name === 'login') {
    next();
  } if (userToken) {
    let userTokenDecoded = jwt_decode(userToken);
    let roles = userTokenDecoded.role;
    if (!roles) {
      alert('You do not have permission to view this UI');
      return;
    }

    if (roles.includes(to.name)) {
      next();
    } else {
      alert('You do not have permission to view this UI: ' + to.name);
    }
  }
});

// eslint-disable-next-line no-unused-vars
const app = new Vue({
  el: '#app',
  router,
  vuetify,
  render: h => h(App)
});
