<template>
  <v-card>
    <v-container class="bv-example-row" fluid>
      <v-row>
        <v-toolbar
            color="grey"
            dark
            dense
            flat
        >
          <v-toolbar-title class="text-md-body-1">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
      </v-row>
      <v-row>
        <v-btn-toggle dense>
          <v-btn :disabled="isStartDisabled()" v-on:click="startDemo()">Start</v-btn>
          <v-btn :disabled="isStopDisabled()" v-on:click="stopDemo()">Stop</v-btn>
          <v-btn v-on:click="resetDemo()">Reset</v-btn>
          <v-btn v-on:click="configure()" disabled="true">Configure</v-btn>
        </v-btn-toggle>
        <v-dialog
            v-model="dialog"
        >
          <v-card>
            <v-row>
              <Configuration/>
            </v-row>
            <v-row>
              <v-btn v-on:click="okCancelClick()">
                OK
              </v-btn>
              <v-btn v-on:click="okCancelClick()">
                Cancel
              </v-btn>
            </v-row>
          </v-card>
        </v-dialog>
        <v-chip>
          {{ demoState }}
        </v-chip>
      </v-row>
      <v-row>
        <v-col>
          <OperatorView/>
        </v-col>
        <v-col>
          <DemoStats/>
        </v-col>
      </v-row>
      <v-row>
        <v-text-field/>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>

import OperatorView from '../Dashboard/WarehouseOverview/OperatorView.vue';
import ResponseTimeMetrics from './ResponseTimeMetrics.vue';
import Configuration from './Configuration.vue';
import ReceivingView from '../Dashboard/WarehouseOverview/ReceivingView.vue';
import DemoStats from './DemoStats.vue';

export default {
  name: 'Simulation',
  components: {
      OperatorView,
      ResponseTimeMetrics,
      Configuration,
      ReceivingView,
      DemoStats,
  },
  data() {
      return {
          demoState: 'Stopped',
          breadcrumbs: [
            { text: "Home", href: "/" },
            { text: "Demos" },
            { text: "Basic Picking Demo" }
          ],
        dialog: false,
        title: 'eCommerce Demo',
        configurations: [],
      }
  },
  methods: {
    startDemo: function() {
        this.demoState = 'Starting...'
        this.doDemo('start')
    },
    stopDemo: function() {
        this.demoState = 'Stopping...'
        this.doDemo('stop')
    },
    resetDemo: function() {
        this.demoState = 'Resetting...'
        this.doDemo('reset')
    },
    doDemo: function(status) {
      const appsUrl = 'http://' + this.$host + ':' + this.$simulationPort + '/' + status
          this.$http.post(appsUrl)
              .then((result) => {
                  this.demoState = result.data.status
              })
    },
    configureDemo: function() {
      // location.href = '#/demos/configuration'
    },
    isStartDisabled: function() {
      if (this.demoState === 'Started' || this.demoState === 'Starting...') {
        return true
      }

      return false
    },
    isStopDisabled: function() {
      if (this.demoState === 'Stopped' || this.demoState === 'Stopping...') {
        return true
      }

      return false
    },
    getStatus() {
      const appsUrl = 'http://' + this.$host + ':' + this.$simulationPort + '/status'
      this.$http.get(appsUrl)
        .then((result) => {
          this.demoState = result.data.status
        });
    },
    configure() {
        this.dialog = true;
    },
    submitConfigurations: function() {

    },
    okCancelClick: function() {
        this.dialog = false;
    }
  },
  mounted: function () {
    this.getStatus();
    setInterval(this.getStatus(), 30_000);
  }  
}
</script>